import * as noUiSlider from 'nouislider';
import * as wNumb from 'wnumb';
import $ from 'jquery'
import { debounce } from 'throttle-debounce';

export default () => {

  let sliderContainer;

  $('.catalog__filters-type').each((i, item) => {
    $(item).on('click', function(e) {
      e.preventDefault();
      $('.catalog__filters-type').removeClass('catalog__filters-type--selected');
      $(item).addClass('catalog__filters-type--selected');
      $('#mobile-type-select').val($(this).attr('data-link-value')).trigger('change');
    });

  });

  $('#mobile-type-select').change(function () {
    /*if ($(this).find('option:selected').attr('value') === '4') {
      $('.catalog__items').hide();
      $('.catalog__buttons').hide();
      $('.catalog__filters-row--subfilters').hide();
      $('.catalog__form').show();
    } else {*/
      $('.catalog__items').show();
      $('.catalog__buttons').show();
      $('.catalog__filters-row--subfilters').show();
      $('.catalog__form').hide();

      let minValue = $(this).find('option:selected').data('min-value');
      let maxValue = $(this).find('option:selected').data('max-value');
      sliderContainer.noUiSlider.set([minValue, maxValue]);
      $('#nvr-type').val($(this).find('option:selected').attr('data-category-value'));

      if ( $(this).find('option:selected').data('body-value') != undefined ) {
        $('.select-dropdown__dropdown-item[data-value="'+$(this).find('option:selected').data('body-value')+'"]').click();
      } else {
        $('.select-dropdown__dropdown-item[data-value="all"]').click();
      }

      $('#nvrs-filters').trigger('change');
    // }
  });

  $('.range-slider').each(function() {
    let item = this;

    sliderContainer = document.createElement("div");
    sliderContainer.className = 'range-slider__slider';
    item.appendChild(sliderContainer);

    if (item.querySelectorAll('input').length === 2) {
      let inputMin = item.querySelectorAll('input')[0];
      let inputMax = item.querySelectorAll('input')[1];

      let sliderOptions = {
        start: [
          inputMin.value ? parseInt(inputMin.value) : 1,
          inputMax.value ? parseInt(inputMax.value) : 10,
        ],
        connect: true,
        tooltips: true,
        step: 1,
        margin: 10,
        format: wNumb({
          decimals: 0
        }),
      };
      if (item.dataset && item.dataset.min !== item.dataset.max) {
        sliderOptions['range'] = {
          'min': parseInt(item.dataset.min),
          'max': parseInt(item.dataset.max)
        };
        noUiSlider.create(sliderContainer, sliderOptions);
        sliderContainer.noUiSlider.on('update', function (values, handle) {
          inputMin.value = values[0];
          inputMax.value = values[1];
          $('#nvrs-filters').trigger('change');
        });

        inputMin.addEventListener('change', function () {
          sliderContainer.noUiSlider.set([this.value, null]);
        });

        inputMax.addEventListener('change', function () {
          sliderContainer.noUiSlider.set([null, this.value]);
        });
      }
    }

  });

  let loadItems = function(page, callback) {
    let data = $('#nvrs-filters').serializeArray().reduce(function(obj, item) {
        obj[item.name] = item.value;
        return obj;
      }, {});

    if (page) {
      data['page'] = page;
    }
    
    if ( $.isEmptyObject(data) && $('.catalog-urm-list') != undefined ){
      data = {urm: 'all'};
    }
    
    $('.catalog__items').addClass('catalog__items--loading');
    $.ajax({
      type: 'POST',
      url: `${window.location.origin}${window.location.pathname}`,
      data: data,
      success: function (data) {
        
        if (data) {
          if (page && callback) {
            $('.catalog__item:not(.catalog__item--info)').last().after(data['items']);
            callback()
          } else {
            $('#nvrs-load-more').attr('data-page', 1);
            $('.catalog__item:not(.catalog__item--info)').remove();
            $('.catalog__items').prepend(data['items']);
          }

          if (data['count'] <= $('.catalog__item:not(.catalog__item--info)').length) {
            $('#nvrs-load-more').hide();
          } else {
            $('#nvrs-load-more').show();
          }
        }
        $('.catalog__items').removeClass('catalog__items--loading');
      },
      error: function(response){
        console.log(response.responseText);
      }
    })
  }

  $('#nvrs-filters').change(debounce(400, (e) => {
    e.preventDefault();
    loadItems();
  }));

  $(document).ready(function(){
    if ($('#nvrs-filters').length) {
      loadItems();
    }
  });

  $('#nvrs-load-more').click(function (e) {
    e.preventDefault();
    let self = $(this);
    loadItems(self.attr('data-page'),() => {
      self.attr('data-page', parseInt(self.attr('data-page')) + 1);
    });
  });

  if ( $('.catalog-urm-list') != undefined ){
    loadItems();
  }

}
