const $ = window.jQuery = require('jquery')

export default () => {

  let render = () => {
    $('form.form input[type=checkbox]').each(function () {

      if ($(this).attr('data-custom-checkbox-rendered') === undefined) {
        let labelTitle = $(this).prev('label').html()
        let labelWrapper = $(this).prev('label').attr('class', 'form__checkbox-input').html('<span class="form__checkbox-icon"></span>').wrap('<div class="form__checkbox"></div>')
        let formWrapper = labelWrapper.parent()
        $(this).addClass().detach().prependTo(labelWrapper)
        formWrapper.append(`<div class="form__checkbox-title">${labelTitle}</div>`)

        $(this).attr('data-custom-checkbox-rendered', 'true')
      }

    })
  }

  let renderComboOtherInput = () => {
    $('.form__combo').each(function () {
      if ($(this).find('input[type=checkbox][value=other]').prop('checked')) {
        $(this).parents('form').find(`.form__combo--${$(this).attr('data-combo-link')}`).show()
      } else {
        $(this).parents('form').find(`.form__combo--${$(this).attr('data-combo-link')}`).hide()
      }
    })
  }

  let renderOtherInput = () => {
    $('.form__checkbox').each(function(inx){
      if ( $(this).find('input[type="checkbox"][value="other_field"]').length ){
        let currentCheckbox = $(this).find('input[type="checkbox"][value="other_field"]');
        let checkboxWrapper = $(this);
        let inputField = '<input type="text" id="'+$(currentCheckbox).attr('id')+'_i" class="form__input form__input_other">';

        $(checkboxWrapper).append(inputField);

        /*$(checkboxWrapper).find('.form__input_other').on('change', function(){
          console.log(inx);
            var parent = $(this).parent(),
                currentCheckbox = $(parent).find('input[type="checkbox"]'),
                currentVal = $(this);
            
            if ( $(this).val() == '' ){
              if ( $(currentCheckbox).prop('checked') ){
                  $(currentCheckbox).prop('checked', false);
              }

              setInterval(function(){
                currentCheckbox[0].setAttribute('value', 'other_field');
                $(currentCheckbox).val('other_field');
              }, 200);
              
            } else {
              if ( !$(currentCheckbox).prop('checked') ){
                  $(currentCheckbox).prop('checked', true);
              }

              setInterval(function(){
                currentCheckbox[0].setAttribute('value', $(currentVal).val());              
                $(currentCheckbox).val($(currentVal).val());
                console.log($(currentVal).val());
                console.log(currentCheckbox[0].getAttribute('value'));
              }, 200);
              
            }

            console.log($('form[name="project_reg"]').serialize());
        });*/
      }
    })
  }

  function setOtherValue(input){
    var currentValue = $(input).val(),
      currentCheckbox = $(input).parent().find('input[type=checkbox]');

    if ( currentValue != '' && currentValue != $(currentCheckbox).val() ){
        // $(currentCheckbox).val(currentValue);
        currentCheckbox[0].setAttribute('value', currentValue);
    } else if ( currentValue != '' ){
        // $(currentCheckbox).val('other_field');
        currentCheckbox[0].setAttribute('value', 'other_field');
    }
  }

  render()
  renderComboOtherInput()
  renderOtherInput()

  $('body')
    .on('formUpdate', () => {
      render()
      renderComboOtherInput()
      renderOtherInput()
    })
    .on('change', 'input[type=checkbox][value=other]', function () {
      renderComboOtherInput()
    })
    .on('keyup', 'input.form__input_other', function(){
      setOtherValue($(this));
    });

};
