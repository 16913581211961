const $ = window.jQuery = require('jquery');
import * as _ from "underscore";

export default () => {

  const submitForm = (formElement, data, recaptchaCallback) => {
    $.ajax({
      type: 'POST',
      url: formElement.attr('action'),
      data: data,
      success: function (data, status, xhr) {
        if (recaptchaCallback !== undefined) {
          recaptchaCallback()
        }

        let ct = xhr.getResponseHeader('content-type') || '';
        if (ct.indexOf('html') > -1) {
          formElement.replaceWith(data);
          $('body').trigger('formUpdate');

          let headerOffset = 0;
          if (window.matchMedia('(min-width: 968px)').matches) {
            headerOffset = $('.header-sticky').height() + 50;
          } else {
            headerOffset = 10;
          }

          $("body,html").animate(
            {
              scrollTop: $(`form[name="${formElement.attr('name')}"]`).offset().top - headerOffset
            },
            200
          );

          formElement.removeClass('form--loading');
        } else if (ct.indexOf('json') > -1) {
          if (data.type === "error") {
            Object.keys(data.errors).map(function(key) {
              let value = data.errors[key];
              if (key === 'common') {
                value.forEach(errorTitle => {
                  formElement.find('.form__error--common').append(`<p>${errorTitle}</p>`);
                });
              } else {
                let errorWrapper = formElement.find(`[name="${formElement.attr('name')}[${key}]"]`).parents('.form__group').find('.form__error');
                value.forEach(errorTitle => {
                  errorWrapper.append(`<p>${errorTitle}</p>`);
                });
              }
            });

            formElement.removeClass('form--loading');
          } else if (data.type === "success") {
            try {
                sGoal(JSON.parse(formElement.attr('goal')));
            } catch(e){

            }            
            
            formElement.find('.form__success').append(`<p>${data.message}</p>`);
            formElement.get(0).reset();

            formElement.removeClass('form--loading');
          } else if (data.type === "redirect") {
            window.location.href = data.url;
          }
        }
      },
      error: function(response) {
        formElement.removeClass('form--loading');
        alert('Problem with your internet connection, please try again.');
      }
    })
  }

  const sGoal = function(object){
      if ( object.yandex !== undefined ){
          if ( typeof window.Ya !== 'undefined' ){
              var _counter = window.Ya._metrika.wv2Counter;

              try {
                  ym(_counter, 'reachGoal', object.yandex);
              } catch(e){

              }
          }
      }

      if ( object.google !== undefined ){
          if ( window.ga !== undefined ){
              try {
                  var tracker = ga.getAll()[0];

                  if ( tracker ){
                      tracker.send("event", object.google.category, object.google.action);
                  }
              } catch(e) {
                  
              }
          }
      }
  }
  
  $('body').on('submit', 'form.form--default', function(e){
    e.preventDefault();

    let self = $(this);
    self.addClass('form--loading');
    self.find('.form__error').html('');
    self.find('.form__success').html('');

    let formData = self.serializeArray();
    let data = formData.reduce(function(obj, item) {
      let isArray = _.filter(formData, function(filterItem, index) {
        return _.contains(filterItem, item.name);
      }).length > 1;
      if (isArray) {
        if (obj[item.name] === undefined) {
          obj[item.name] = [];
        }
        obj[item.name].push(item.value);
      } else {
        obj[item.name] = item.value;
      }
      return obj;
    }, {});
    
    data['locale'] = $('body').data('locale');
    if ($(document.activeElement).length && $(document.activeElement).hasClass('form__back')) {
      data[$(document.activeElement).attr('name')] = $(document.activeElement).attr('value');
    }

    if (window.grecaptcha && $(this).find('.g-recaptcha').length) {
      window.grecaptcha.execute($(this).find('.g-recaptcha').data('site-key'))
        .then(function(token) {
          if (token) {
            if (data['g-recaptcha-response'] === undefined || data['g-recaptcha-response'] === '') {
              data['g-recaptcha-response'] = token;
            }
            submitForm(self, data, () => {
              window.grecaptcha.reset();
            });
          } else {
            alert('You were detected as a robot, could you try again please?');
          }
        });
    } else {
      submitForm(self, data);
    }
  });

};
