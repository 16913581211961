const $ = window.jQuery = require('jquery')

export default () => {

  let render = () => {
    $('form.form input[type=radio]').each(function () {

      if ($(this).attr('data-custom-radio-rendered') === undefined) {
        let labelTitle = $(this).prev('label').html()
        let labelWrapper = $(this).prev('label').attr('class', 'form__radio-input').html('<span class="form__radio-icon"></span>').wrap('<div class="form__radio"></div>')
        let formWrapper = labelWrapper.parent()
        $(this).addClass().detach().prependTo(labelWrapper)
        formWrapper.append(`<div class="form__radio-title">${labelTitle}</div>`)

        $(this).attr('data-custom-radio-rendered', 'true')
      }

    })
  }

  let renderComboOtherInput = () => {
    $('.form__combo').each(function () {
      if ($(this).find('input[type=radio][value=other]').prop('checked')) {
        $(this).parents('form').find(`.form__combo--${$(this).attr('data-combo-link')}`).show()
      } else {
        $(this).parents('form').find(`.form__combo--${$(this).attr('data-combo-link')}`).hide()
      }
    })
  }

  render()
  renderComboOtherInput()

  $('body')
    .on('formUpdate', () => {
      render()
      renderComboOtherInput()
    })
    .on('change', 'input[type=radio][value=other]', function () {
      renderComboOtherInput()
    })

};
